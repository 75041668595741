<template>
	<div>
		<!-- Toolbar -->
		<n-toolbar title="訂單" left-text="返回" @clickLeft="$router.go(-1)" />
		<!-- Content -->
		<div class="bg-secondary h-screen overflow-auto p-3 relative text-white">
			<n-list-item class="text-lg" :start="`共有${currentAdjustment.products.length}筆`"></n-list-item>
			<!-- Cart list -->
			<div class="flex flex-col gap-5 pb-48">
				<n-button v-for="product in currentAdjustment.products" :key="product.id" color="light" class="active:bg-primary active:text-white w-full text-xl" @click="$router.push(`/product/${product.id}`)">
					<n-list-item :start="product.name" :end="`${product.quantity}${product.defaultUnit}`" :items="unitItems(product.units)"></n-list-item>
				</n-button>
			</div>
		</div>
		<!-- Content footer -->
		<div class="fixed bottom-0 w-full h-36 p-5 bg-secondary text-white flex flex-col space-y-3">
			<n-list-item class="text-2xl items-end" start="🔥熱門商品" @click="$router.push(`/category/${currentShop.hotCategoryId}`)">
				<p slot="end" class="text-lg">未加入: {{ hotCateogryProductCount }}項</p>
			</n-list-item>
			<n-button @click="showAlert = true">上傳訂單</n-button>
		</div>
		<!-- Alert -->
		<n-alert v-if="showAlert" :isError="hotCateogryProductCount > 0" :message="`${hotCateogryProductCount}項熱門商品尚未加入，確認已檢查？`" @cancel="showAlert = false" @ok="showAlert = false; showConfirmAlert = true;" />
		<!-- Alert -->
		<n-alert v-if="showConfirmAlert" :message="`確認上傳？`" @cancel="showConfirmAlert = false" @ok="setAdjustmentStatus" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			showAlert: false,
			showConfirmAlert: false,
		}
	},

	computed: {
		...mapGetters(['currentShop', 'currentAdjustment', 'catalogItem']),

		hotCateogryProductCount() {
			const productIds = this.catalogItem(this.currentShop.hotCategoryId).category.productIds
			const result = productIds.filter(id => this.currentAdjustment.products.find(product => product.id === id))
			return productIds.length - result.length
		},
	},

	methods: {
		unitItems(units) {
			const result = []
			units.forEach(unit => {
				if (unit.value === 0) return
				result.push(`${unit.value}${unit.text}`)
			})
			return result
		},

		async setAdjustmentStatus() {
			try {
				this.showAlert = false
				await this.$store.dispatch('setLoading', { status: true })
				await this.$store.dispatch('setAdjustmentStatus', { adjustment: this.currentAdjustment, status: 'CLOSED' })
				await this.$store.dispatch('setLoading', { status: false })
				return this.$router.push('/inventory')
			} catch (e) {
				console.error(e)
				await this.$store.dispatch('setError', { message: e.message, status: true })
				await this.$store.dispatch('setLoading', { status: false })
			}
		},
	},
}
</script>
